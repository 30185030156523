import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CHeaderBrand } from '@coreui/react';
import { isMobile } from 'react-device-detect';
// routes config
import { Link } from 'react-router-dom';

function LandingHeader({ setShowModal }) {
  const dispatch = useDispatch();
  const sidebarShow = useSelector((state) => state.sidebarShow);

  const toggleSidebar = () => {
    const val = [true, 'responsive'].includes(sidebarShow) ? false : 'responsive';
    dispatch({
      type: 'set',
      sidebarShow: val,
    });
  };

  const toggleSidebarMobile = () => {
    const val = [false, 'responsive'].includes(sidebarShow) ? true : 'responsive';
    dispatch({
      type: 'set',
      sidebarShow: val,
    });
  };

  const mobileCollapse = () => {
    if (document.getElementById('navbarNavAltMarkup')
      .classList
      .contains('show')) {
      document.getElementById('navbarNavAltMarkup')
        .classList
        .remove('show');
    } else {
      document.getElementById('navbarNavAltMarkup')
        .classList
        .add('show');
    }
  };
  return (
    <nav className="navbar navbar-light bg-transparent pl-3 pt-3">
      <CHeaderBrand className={isMobile && 'main-landing-logo-mobile-header'}>
        <Link to="/home">
          <img
            className={isMobile ? 'main-landing-logo-mobile mx-auto' : 'main-landing-logo-desktop'}
            src="img/logo/sharpe-blue-200px.png"
            alt=""
          />
        </Link>
      </CHeaderBrand>

      {!isMobile
        ? (
          <div className="row main-landing-login">
            <Link
              className="nav-link text-dark font-lg font-weight-light mx-md-2"
              to="/solutions"
            >
              Solutions
            </Link>
            <Link
              className="nav-link btn font-weight-bolder font-lg btn-outline-info mx-md-3 border-0"
              to="/login"
            >
              Login
            </Link>
            {window.IS_VAVE
              ? (
                <Link
                  className="nav-link btn font-weight-bolder font-lg btn-outline-info mx-md-3"
                  to="/register"
                >
                  Sign
                  Up
                </Link>
              )
              : (
                <button
                  className="nav-link btn font-weight-bolder font-lg btn-outline-info mx-md-3"
                  onClick={() => setShowModal(true)}
                >
                  Request a Demo
                </button>
              )}
          </div>
        )
        : (
          <div className="home-mobile-btns">
            <Link
              className="nav-link text-dark font-lg font-weight-light mx-md-3"
              to="/solutions"
            >
              Solutions
            </Link>
            <Link
              className="nav-link btn font-weight-bolder font-lg btn-outline-info border-0"
              to="/login"
            >
              Login
            </Link>
            {window.IS_VAVE
              ? (
                <Link
                  className="nav-link font-weight-bolder font-lg btn btn-outline-info"
                  to="/register"
                >
                  Sign Up
                </Link>
              )
              : (
                <button
                  onClick={() => setShowModal(true)}
                  className="nav-link font-weight-bolder font-lg btn btn-outline-info"
                  to="/register"
                >
                  Request a
                  Demo
                </button>
              )}
          </div>
        )}
    </nav>
  );
}

export default LandingHeader;
