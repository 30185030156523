import { createSelector } from 'reselect';

const AssetsState = (state) => state.rootReducer;
const LoginState = (state) => state.authReducer;
const LoadingState = (state) => state.loadingReducer;

export const makeSelectAssets = createSelector(
  AssetsState,
  (rootReducer) => rootReducer.assets
);
export const makeSelectAssetGroups = createSelector(
  AssetsState,
  (rootReducer) => rootReducer.assetGroups
);
export const makeSelectSavedPortfolios = createSelector(
  AssetsState,
  (rootReducer) => rootReducer.savedPortfolios
);
export const makeSelectFavoriteAssets = createSelector(
  AssetsState,
  (rootReducer) => rootReducer.favorites
);
export const makeSelectSavedAssets = createSelector(
  AssetsState,
  (rootReducer) => rootReducer.savedAssets
);
export const makeSelectLoading = createSelector(
  LoadingState,
  (loadingReducer) => loadingReducer.loading
);
export const makeSelectLoginCreds = createSelector(
  LoginState,
  (authReducer) => authReducer.creds
);
export const makeSelectChangePasswordOtp = createSelector(
  LoginState,
  (authReducer) => authReducer.loginProcedure.otp
);
export const makeSelectUsername = createSelector(
  LoginState,
  (authReducer) => authReducer.loginProcedure.username
);
export const makeSelectUserAssets = createSelector(
  AssetsState,
  (rootReducer) => rootReducer.userAssets
);
export const makeSelectTimeseriesVisible = createSelector(
  AssetsState,
  (rootReducer) => rootReducer.visible
);
export const makeUpdateState = createSelector(
  AssetsState,
  (rootReducer) => rootReducer.updateState
);
