import React, { useState } from 'react';
import {
  CButton,
  CCol,
  CFooter,
  CForm,
  CFormGroup,
  CInput,
  CLabel,
  CLink,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTextarea,
} from '@coreui/react';
import MaskedInput from 'react-text-mask';
import CIcon from '@coreui/icons-react';
import { useNavigate } from 'react-router-dom';
import API from '../api';
import TermsModalAccessible from '../pages/main/pages/landing/TermsModalAccessible';

function TheFooter() {
  const [showModal, setShowModal] = useState(false);
  const [small, setSmall] = useState(false);
  const [inputs, setInputs] = useState({
    name: '',
    email: '',
    phone_number: '',
    company_name: '',
    position: '',
    enquiry: '',
  });
  const onChangeHandler = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };
  const submitEnquiry = (e) => {
    e.preventDefault();
    setShowModal(false);
    API.post('userenquiries', inputs)
      .then((r) => {
        if (r.status === 200) {
          setSmall(true);
          setTimeout(() => {
            setSmall(false);
          }, 15000);
        }
      });
  };
  const navigate = useNavigate();
  return (
    <>
      <CFooter className="px-0 bg-transparent" fixed={false}>
        {/* <a href="https://coreui.io" target="_blank" rel="noopener noreferrer">Disclaimer</a> */}
        {/* <span className="ml-1">&copy; 2021</span> */}
        <div style={{ backgroundColor: 'white' }} className="text-dark-50 w-100 pt-3 pb-3 px-5">
          <CRow>
            <CCol md={12} className="row">
              {/* <p className="mx-2">Team</p> */}
              {/* <p className="mx-2">Careers</p> */}
              <p
                style={{ cursor: 'pointer' }}
                className="mx-2"
                onClick={() => {
                  navigate('/terms');
                }}
              >
                Terms
              </p>
              <p
                style={{ cursor: 'pointer' }}
                className="mx-2"
                onClick={() => {
                  navigate('/privacy-policy');
                }}
              >
                Privacy
              </p>
              <p
                style={{ cursor: 'pointer' }}
                className="mx-2"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                Contact Us
              </p>
            </CCol>
            <CCol md={12}>
              <p className="float-right font-weight-lighter">
                © {window.COPY_RIGHT}
                {window.SITE_TITLE}
              </p>
            </CCol>
          </CRow>
          <hr className="mt-0" />
          <p className="font-weight-lighter pt-4">
            Please note, the information provided on this
            website is for
            informational purposes only and investors should determine for themselves whether a
            particular service or
            product is suitable for their investment needs.
            <br />
            Certain asset classes and investments have shorter histories than others and may not be
            as reliable. Market
            Events and other factors may influence the reliability of the potential outcomes. All
            investment strategies
            have the potential for profit or loss.
            <br />
            Back-tested performance results have inherent limitations, particularly in the fact that
            these results do
            not
            represent actual trading and may not reflect the impact that material economic and
            market factors might have
            placed.
            <br />
            Changes in investment strategies, contributions or withdrawals, and economic conditions,
            may materially
            alter
            the performance of your portfolio. Different types of investments involve varying
            degrees of risk, and there
            can be no assurance that any specific investment or strategy will either be suitable or
            profitable for a
            client’s portfolio. There are no assurances that the portfolio will match or outperform
            any particular
            benchmark.
            <br />
            Monte Carlo simulations have material limitations. Market movements may be more or less
            extreme and more or
            less frequent than those that occur in the model.
            <br />
            Nothing provided herein constitutes tax advice. Individuals should seek the advice of
            their own tax advisor
            for specific information regarding tax consequences of investments. Investments in
            securities entail risk
            and
            are not suitable for all investors. This is not a recommendation nor an offer to sell
            (or solicitation of an
            offer to buy) securities in any jurisdiction.
          </p>
        </div>
        {/* <div className="mfs-auto"> */}
        {/*  <span className="mr-1">Powered by</span> */}
        {/*  <a href="https://coreui.io/react" target="_blank" rel="noopener noreferrer">React.js</a> */}
        {/* </div> */}
        <CModal
          show={showModal}
          onClose={() => {
            setShowModal(false);
          }}
          size={window.IS_VAVE ? 'md' : 'lg'}
        >
          <CModalHeader className="border-bottom-0">
            <CModalTitle className="mx-auto mt-2" style={{ fontSize: 35 }}>Contact Us</CModalTitle>
          </CModalHeader>
          <CForm onSubmit={(e) => {
            submitEnquiry(e);
          }}
          >
            <CModalBody className="px-5">
              <CRow>
                {
                  !window.IS_VAVE
                  && (
                  <CCol md="5" className="font-lg my-auto">
                    <CRow>
                      <p className="font-weight-bold mr-4">Email: </p>
                      <p>{window.SITE_EMAIL}</p>
                    </CRow>
                    <CRow>
                      <p className="font-weight-bold mr-3">Phone: </p>
                      <p>+98 912 283 3276</p>
                    </CRow>
                  </CCol>
                  )
                }
                <CCol md={window.IS_VAVE ? 12 : 7}>
                  <CFormGroup row className="my-0">
                    <CCol md="12">
                      <CFormGroup>
                        <CLabel htmlFor="city">
                          Name
                          <strong
                            className="text-danger"
                          >
                            *
                          </strong>
                        </CLabel>
                        <CInput id="city" required name="name" onChange={onChangeHandler} />
                      </CFormGroup>
                    </CCol>
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel htmlFor="vat">
                      Email
                      <strong className="text-danger">*</strong>
                    </CLabel>
                    <CInput
                      id="vat"
                      type="email"
                      required
                      name="email"
                      onChange={onChangeHandler}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel htmlFor="street">Phone Number </CLabel>
                    {/* <CInput id="street" placeholder="Enter personal phone number"/> */}
                    <MaskedInput
                      mask={['+', /[1-9]/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
                      className="form-control"
                      guide
                      id="my-input-id"
                      name="phone_number"
                      requried
                      onChange={onChangeHandler}
                    />
                    <small className="form-text text-muted">ex. +44 999 999 9999</small>
                  </CFormGroup>
                  <CFormGroup row className="my-0">
                    <CCol xs="7">
                      <CFormGroup>
                        <CLabel htmlFor="city">Company Name </CLabel>
                        <CInput
                          id="city"
                          name="company_name"
                          onChange={onChangeHandler}
                        />
                      </CFormGroup>
                    </CCol>
                    <CCol xs="5">
                      <CFormGroup>
                        <CLabel htmlFor="postal-code">Position</CLabel>
                        <CInput
                          id="postal-code"
                          name="position"
                          onChange={onChangeHandler}
                        />
                      </CFormGroup>
                    </CCol>
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel htmlFor="country">Message </CLabel>
                    <CTextarea onChange={onChangeHandler} name="enquiry" />
                  </CFormGroup>
                </CCol>
              </CRow>
            </CModalBody>
            <CModalFooter>
              <CButton type="submit" className="text-info">Submit</CButton>
            </CModalFooter>
          </CForm>
        </CModal>
        <CModal
          show={small}
          onClose={() => setSmall(!small)}
          className="mt-5"
          size="md"
        >
          <CModalHeader className="border-bottom-0">
            <CLink className="card-header-action ml-auto" onClick={() => setSmall(false)}>
              <CIcon name="cil-x" />
            </CLink>
          </CModalHeader>
          <CModalBody className="text-center mb-4">
            <h2 style={{ fontSize: 35 }} className="pb-3">Thank you! </h2>
            <p className="font-lg">
              We will return to you as soon as possible.
            </p>
          </CModalBody>
        </CModal>
      </CFooter>
      <TermsModalAccessible />
    </>
  );
}

export default React.memo(TheFooter);
