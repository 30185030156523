import { ActionTypes } from './constants';

const defaultState = {
  creds: {
    isFirstLogin: false,
    id: null,
    username: null,
    name: null,
    surname: null,
    phone: null,
    email: null,
    company: null,
    position: null,
    setting: {
      date_system: 'gregorian_calendar',
      data_resampling: 'end_of_period',
      date_picker_interval: 1,
      base_currency: 'usd',
      color_palette: 'palette1',
      initial_capital: 100000000,
    }
  },
  loginProcedure: {
    otp: '',
    username: ''
  }
};

export default function authReducer(state = defaultState, action) {
  switch (action.type) {
    case ActionTypes.login.LOGIN:
      return {
        ...state,
        creds: action.payload
      };
    case ActionTypes.SET_CHANGE_PASSWORD_OTP:
      return {
        ...state,
        loginProcedure: {
          ...state.loginProcedure,
          otp: action.payload
        }
      };
    case ActionTypes.SET_USERNAME:
      return {
        ...state,
        loginProcedure: {
          ...state.loginProcedure,
          username: action.payload
        }
      };
    default:
      return state;
  }
}
