import { ActionTypes } from './constants';

const defaultState = {
  assets: {},
  savedPortfolios: [],
  favorites: {},
  savedAssets: [],
  assetGroups: [],
  userAssets: [],
  visible: false,
  updateState: false
};

export default function rootReducer(state = defaultState, action) {
  switch (action.type) {
    case ActionTypes.SET_ASSETS:
      return {
        ...state,
        assets: action.payload
      };
    case ActionTypes.SET_SAVED_PORTFOLIOS:
      return {
        ...state,
        savedPortfolios: action.payload
      };
    case ActionTypes.SET_FAVORITE_ASSETS:
      return {
        ...state,
        favorites: action.payload
      };
    case ActionTypes.SET_SAVED_ASSETS:
      return {
        ...state,
        savedAssets: action.payload
      };
    case ActionTypes.SET_ASSET_GROUPS:
      return {
        ...state,
        assetGroups: action.payload
      };
    case ActionTypes.SET_USER_ASSET:
      return {
        ...state,
        userAssets: action.payload
      };
    case ActionTypes.SET_TIMESERIES_VISIBLE:
      return {
        ...state,
        visible: action.payload
      };
    case ActionTypes.SET_UPDATE_STATE:
      return {
        ...state,
        updateState: !state.updateState
      };
    default:
      return state;
  }
}
