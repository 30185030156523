import { ActionTypes } from './constants';

export const setAssets = (assets) => ({
  type: ActionTypes.SET_ASSETS,
  payload: assets,
});
export const setSavedPortfolios = (assets) => ({
  type: ActionTypes.SET_SAVED_PORTFOLIOS,
  payload: assets,
});
export const setAssetGroups = (assets) => ({
  type: ActionTypes.SET_ASSET_GROUPS,
  payload: assets,
});
export const setFavoriteAssets = (assets) => ({
  type: ActionTypes.SET_FAVORITE_ASSETS,
  payload: assets,
});
export const setLoading = (loading) => ({
  type: ActionTypes.SET_LOADING,
  payload: loading
});
export const setLoginCreds = (creds) => ({
  type: ActionTypes.login.LOGIN,
  payload: creds
});
export const setSavedAssets = (assets) => ({
  type: ActionTypes.SET_SAVED_ASSETS,
  payload: assets
});
export const setChangePasswordOtp = (code) => ({
  type: ActionTypes.SET_CHANGE_PASSWORD_OTP,
  payload: code
});
export const setUsername = (username) => ({
  type: ActionTypes.SET_USERNAME,
  payload: username
});
export const setUserAsset = (assets) => ({
  type: ActionTypes.SET_USER_ASSET,
  payload: assets,
});
export const setTimeseriesVisible = (visible) => ({
  type: ActionTypes.SET_TIMESERIES_VISIBLE,
  payload: visible
});
export const setUpdateState = () => ({
  type: ActionTypes.SET_UPDATE_STATE
});
