export const ActionTypes = {
  SET_ASSETS: 'SET_ASSETS',
  SET_SAVED_PORTFOLIOS: 'SET_SAVED_PORTFOLIOS',
  SET_SAVED_ASSETS: 'SET_SAVED_ASSETS',
  SET_FAVORITE_ASSETS: 'SET_FAVORITE_ASSETS',
  SET_LOADING: 'SET_LOADING',
  login: {
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT'
  },
  SET_CHANGE_PASSWORD_OTP: 'SET_CHANGE_PASSWORD_OTP',
  SET_USERNAME: 'SET_USERNAME',
  SET_ASSET_GROUPS: 'SET_ASSET_GROUPS',
  SET_TIMESERIES_VISIBLE: 'SET_TIMESERIES_VISIBLE',
  SET_UPDATE_STATE: 'SET_UPDATE_STATE',
  SET_USER_ASSET: 'SET_USER_ASSET'
};
