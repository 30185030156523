import React, { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes, } from 'react-router-dom';
import './scss/style.scss';
import { Helmet } from 'react-helmet';
import { CFade } from '@coreui/react';
import OuterTerms from './pages/main/pages/landing/OuterTerms';
import OuterPrivacy from './pages/main/pages/landing/OuterPrivacy';
import routes from './routes';
// import { Adsense } from "@ctrl/react-adsense";

const Loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));
// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Otp = React.lazy(() => import('./views/pages/Otp/Otp'));
const ForgotPassword = React.lazy(() => import('./views/pages/ForgotPassword/ForgotPassword'));
const ChangePassword = React.lazy(() => import('./views/pages/ChangePassword/ChangePassword'));
const ForgotPasswordOtp = React.lazy(() => import('./views/pages/ForgotPasswordOtp/Otp'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const Solutions = React.lazy(() => import('./pages/main/pages/landing/Solutions'));
const MainLanding = React.lazy(() => import('./pages/main/pages/landing/MainLanding'));
const FundAnalysis = React.lazy(() => import('./pages/main/pages/panel/FundAnalysis'));
const FundPerformanceAnalysis = React.lazy(() => import('./pages/main/pages/panel/FundPerformance'));

function App() {
  // document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');

  useEffect(() => {
    const url = new URL(window.location)
    const token = window.location.search.slice(7)
    if (token) {
      localStorage.setItem('token', token)
      window.location.replace(`${url.origin}`)
    }
  }, [])

  return (
    <>
      {/* <div style={{width:200 , height:200}}>

      <Adsense
        client="ca-pub-5798857555741373"
        style={{display:"inline-block" , width:"100%", height:"100%"}}
        // crossorigin="anonymous"
        // style={{ width: 500, height: 300 }}

        />
        </div> */}

      <Helmet>
        <meta charSet="utf-8" />
        <title>{window.SITE_TITLE}</title>
        <link rel="canonical" href={`${window.WEBSITE_BASE_URL}`} />
        <meta
          name="description"
          content={`${window.SITE_TITLE} is a technology-powered investment management and portfolio analytics application which brings insight to your investment decision-making process and enhances your portfolio performance.`}
        />
      </Helmet>
      <BrowserRouter>
        <React.Suspense fallback={Loading}>
          <Routes>
            <Route
              exact
              path="/login"
              name="Login Page"
              element={<Login />}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              element={<Register />}
            />
            <Route
              exact
              path="/otp"
              name="Otp Page"
              element={<Otp />}
            />
            <Route
              exact
              path="/forgot-password"
              name="Otp Page"
              element={<ForgotPassword />}
            />
            <Route
              exact
              path="/forgot-password-otp"
              name="Otp Page"
              element={<ForgotPasswordOtp />}
            />
            <Route
              exact
              path="/change-password"
              name="Otp Page"
              element={<ChangePassword />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              element={<Page404 />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              element={<Page500 />}
            />
            <Route
              path="/solutions"
              name="Solutions"
              element={<Solutions />}
            />
            <Route
              path="/fund-performance"
              name="FundAnalysis"
              element={<FundAnalysis />}
            />
            <Route
              path="/fund-performance-analysis"
              name="FundPerformanceAnalysis"
              element={<FundPerformanceAnalysis />}
            />
            <Route
              path="/main/*"
              name="Main"
              element={<MainLanding />}
            />
            <Route
              path="/terms"
              name="Terms"
              element={<OuterTerms />}
            />
            <Route
              path="/privacy-policy"
              name="Privacy"
              element={<OuterPrivacy />}
            />
            <Route
              path="/"
              name="Privacy"
              element={
                <TheLayout />
              }
            >
              {routes.map((route, idx) => route.component && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={localStorage.getItem('token') !== null ? (
                    <CFade>
                      <route.component />
                    </CFade>
                  )
                    : <Navigate to="/login" />}
                />
              ))}
            </Route>
          </Routes>
        </React.Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
