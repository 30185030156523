import React from 'react';
import { CFormGroup, CInputCheckbox, CLabel, CLink } from '@coreui/react';
import API from '../../../../api';
import { setLoginCreds } from '../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { makeSelectLoginCreds } from '../redux/selectors';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-scroll';

const actionDispatch = (dispatch) => ({
  setLoginCreds: (creds) => dispatch(setLoginCreds(creds))
});
const credsSelector = createSelector(makeSelectLoginCreds, (creds) => ({
  creds,
}));
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  checkBox: {}
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function TermsModalAccessible({
  modal,
  setModal,
  check,
  setCheck
}) {
  const classes = useStyles();
  const { setLoginCreds } = actionDispatch(useDispatch());
  const { creds } = useSelector(credsSelector);

  const handleClickOpen = () => {
    setLoginCreds({ isFirstLogin: true, ...creds })
    ;
  };

  const handleClose = () => {
    setModal(false);
  };

  const data = {
    tos_signed: true
  };
  const setIsSigned = () => {
    API.patch('usersettings', data)
      .then(r => {
        if (r.status === 200) {
          setLoginCreds({
            ...creds,
            isFirstLogin: false
          });
        }
      });
  };

  return (

    <Dialog disableBackdropClick disableEscapeKeyDown fullScreen open={modal} onClose={handleClose}
            TransitionComponent={Transition}>
      <AppBar className={classes.appBar + ' ' + 'bg-gradient-dark'}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>Terms of Service & Privacy
            Policy</Typography>
        </Toolbar>
      </AppBar>
      <p className="font-weight-lighter p-5 pt-4">
        <div>
          <h1 style={{
            marginRight: '0in',
            marginLeft: '0in',
            fontSize: '32px',
            fontFamily: '"Barlow"',
            color: '#1F4E79',
            fontWeight: 'normal'
          }}>Terms of Service</h1>
          <h2 style={{
            marginRight: '0in',
            marginLeft: '0in',
            fontSize: '24px',
            fontFamily: '"Barlow"',
            color: '#2E74B5',
            fontWeight: 'normal'
          }}>Introduction</h2>
          <p style={{
            margin: '0in',
            fontSize: '16px',
            fontFamily: '"Calibri",sans-serif',
            marginBottom: '7.5pt',
            background: 'inherit'
          }}><span style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;provides tools and content for people interested in quantitative finance and investing, collectively known as the "Site" or "website". Nothing on&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>, or any page within the&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;domain should be considered an offering or recommendation of securities or financial advice. This website is made available for educational and entertainment purposes only. Please read the following important terms and conditions ("Terms of Service") carefully. These Terms of Service govern your access to and use of the Site. This is a legal agreement between you and&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>. YOU UNDERSTAND THAT BY USING THE SITE, OR YOUR ACCOUNT, YOU ARE AGREEING TO BE BOUND BY THESE TERMS OF SERVICE. IF YOU DO NOT ACCEPT THESE TERMS OF SERVICE IN THEIR ENTIRETY, OR ANY SUBSEQUENT MODIFICATIONS THERETO, YOU MAY NOT ACCESS OR USE THE SITE.</span>
          </p>
          <h2 style={{
            marginRight: '0in',
            marginLeft: '0in',
            fontSize: '24px',
            fontFamily: '"Barlow"',
            color: '#2E74B5',
            fontWeight: 'normal'
          }}>Your Acceptance</h2>
          <p style={{
            margin: '0in',
            fontSize: '16px',
            fontFamily: '"Calibri",sans-serif',
            marginBottom: '7.5pt',
            background: 'inherit'
          }}><span style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>Your use of the site is subject to this Terms of Service Agreement and the current&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;</span><u><Link smooth
                                     to="privacy-policy"
                                     style={{
                                       fontSize: '14px',
                                       fontFamily: '"Barlow"',
                                       color: '##333333'
                                     }}>Privacy
            Policy</Link></u><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>. Each time you use the Site, you agree to be bound by the following Terms of Service and our Privacy Policy. If you do not agree to all of these conditions, then you must immediately discontinue your use of the Site.&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;may at any time revise or amend these Terms of Service, and it is your responsibility to review the Terms of Service for any changes each time you visit the Site. Your use of the Site following any revision or amendment of these Terms of Service will signify your assent to and acceptance of the revised Terms of Service. These Terms of Service apply to all customers, visitors, users, and others who view the Site ("Users").</span>
          </p>
          <h2 style={{
            marginRight: '0in',
            marginLeft: '0in',
            fontSize: '24px',
            fontFamily: '"Barlow"',
            color: '#2E74B5',
            fontWeight: 'normal'
          }}>Links to Websites</h2>
          <p style={{
            margin: '0in',
            fontSize: '16px',
            fontFamily: '"Calibri",sans-serif',
            marginBottom: '7.5pt',
            background: 'inherit'
          }}><span style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>The Site may contain links to third-party websites that are not owned or controlled by&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>, including links provided by Users.&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites. By using the Site, you expressly relieve&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;from any and all liability arising from your use of any third-party website. Accordingly, we encourage you to be aware when you leave the Site and to read the Terms of Service and privacy policy of other websites that you visit. Unless we expressly state otherwise, the fact that&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;has provided a link to a site is not an endorsement, authorization, sponsorship, or affiliation with respect to such site, its owners, or its providers.</span>
          </p>
          <h2 style={{
            marginRight: '0in',
            marginLeft: '0in',
            fontSize: '24px',
            fontFamily: '"Barlow"',
            color: '#2E74B5',
            fontWeight: 'normal'
          }}>Information Disclaimer</h2>
          <p style={{
            margin: '0in',
            fontSize: '16px',
            fontFamily: '"Calibri",sans-serif',
            marginBottom: '7.5pt',
            background: 'inherit'
          }}><span style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>The Site may provide certain financial market data, quotes, price forecasts, other forecasts, news, research, predictions, and opinions or other financial information (collectively "Information") that has been independently obtained by certain financial market information services, financial and research publishers, various securities markets including stock exchanges and their affiliates, asset managers, investment bankers, and other providers (collectively the "Information Providers") or has been obtained by&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;from other sources including computer algorithms that may include guessing or other sources of random information. All data and Information is provided "as is" and the Information is intended solely as general information for educational and entertainment purposes only and is neither professional stock broker advice for any investment nor a substitute for other professional advice and services from qualified financial services providers familiar with your financial situation. Decisions based on Information contained within the Site are your sole responsibility. Always seek the advice of your financial advisor or other qualified financial services provider regarding any investment. The Information is provided with the understanding that&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;is not engaged in rendering professional services or advice, and is not a registered investment advisor. Your use of the Site is subject to this disclaimer:&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;assumes no responsibility for any consequences relating directly or indirectly to any action or inaction you take based on the Information or other material on this Site.&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;does not guarantee or certify the accuracy, completeness, timeliness, or correct sequencing of the Information made available through&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>, the Information Providers, or any other third party transmitting the Information (the "Information Transmitters"). You agree that&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>, the Information Providers, and the Information Transmitters shall not be liable in any way for the accuracy, completeness, timeliness, or correct sequencing of the Information, or for any decision made or action taken by you relying upon the Information. You further agree that&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>, the Information Providers, and the Information Transmitters will not be liable in any way for the interruption or cessation in the providing of any data, Information, or other aspect of the Site.&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;is not responsible for, and makes no warranties regarding, the access, speed, or availability of the Internet in general or the Site in particular.&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;reserves the right to modify or discontinue, temporarily or permanently, all or any portion of the Site with or without notice.</span>
          </p>
          <h2 style={{
            marginRight: '0in',
            marginLeft: '0in',
            fontSize: '24px',
            fontFamily: '"Barlow"',
            color: '#2E74B5',
            fontWeight: 'normal'
          }}>Use of the Site</h2>
          <p style={{
            margin: '0in',
            fontSize: '16px',
            fontFamily: '"Calibri",sans-serif',
            marginBottom: '7.5pt',
            background: 'inherit'
          }}><span style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;hereby grants you permission to use the Site as set forth in these Terms of Service provided that you will comply with the Terms of Service and with any Information Providers' Terms of Service linked to the Site. Some of the Information on the Site provided by Information Providers, including but not limited to price and fundamentals data for stocks, funds or other securities, is licensed to us by others; therefore, your use of Information may be governed by an Information Provider's Terms of Service. You may use, print, copy, provide links to, and download content and results ("Content") displayed on the Site for both commercial and non-commercial use where not explicitly forbidden by the Information Providers' Terms of Service. If you use the copied or downloaded content and results you agree to attribute the materials to their source and you agree to maintain all copyright and other proprietary notices contained on the materials. To access some features of the Site, you will have to register on the Site. You may never use another user's registration without permission. When registering, you must provide accurate and complete information. You are solely responsible for the activity that occurs under your registration, and you must keep your password secure. With the exception of applications commonly known as web browser software, or other applications formally approved by&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;in writing, you agree not to use any software, program, application, or any other device to access or log on to the Site or to automate the process of obtaining, downloading, transferring, or transmitting any content, information, or quotes to or from the Site. You agree not to use or launch any automated system, including without limitation, "robots," "spiders," "scrapers," or "offline readers," to access the Site. Notwithstanding the foregoing,&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>grants the operators of public search engines permission to use spiders to copy materials from the Site for the sole purpose of creating publicly available searchable indices of the materials.&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;may permanently or temporarily terminate, suspend, or otherwise refuse to permit User's access to the Site without notice and liability, if in Silicon Cloud Technologies' sole determination, User violates any of the Terms of Service, including the following prohibited acts, which you agree not to perform; (i) use the Site for any illegal purpose or to submit, transmit or facilitate the distribution of information or content that is unlawful, illegal, harmful, abusive, racially or ethnically offensive, vulgar, obscene, sexually explicit, defamatory, infringing, invasive of personal privacy or publicity rights, harassing, libelous, threatening, or in a reasonable person's view, objectionable; (ii) attempt to interfere with, compromise the system integrity or security or decipher any transmissions to or from the servers running the Site; (iii) take any action that imposes an unreasonable or disproportionately large load on our infrastructure; (iv) upload invalid data, viruses, worms, or other software to the Site; (v) impersonate another person or otherwise misrepresent User's affiliation with a person or entity, conduct fraud, hide or attempt to hide User's identity; (vi) submit, upload, post, email, transmit or otherwise make available any Information or Content that User does not have a right to use or make available; (vii) interfere or attempt to interfere with the proper working of the Site; (viii) bypass the measures we may use to prevent or restrict access to the Site; or (ix) reverse engineer, reverse assemble or otherwise attempt to discover any source code.</span>
          </p>
          <h2 style={{
            marginRight: '0in',
            marginLeft: '0in',
            fontSize: '24px',
            fontFamily: '"Barlow"',
            color: '#2E74B5',
            fontWeight: 'normal'
          }}>Intellectual Property</h2>
          <p style={{
            margin: '0in',
            fontSize: '16px',
            fontFamily: '"Calibri",sans-serif',
            marginBottom: '7.5pt',
            background: 'inherit'
          }}><span style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;or their third-party data or content providers, including Information Providers, have exclusive proprietary rights to the Information provided on this Site. Content and the trademarks, service marks, trade names, icons, and logos contained therein ("Marks"), including without limitation are owned by&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;or licensed to&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;and owned by the Information Provider, and are subject to copyright and other intellectual property rights under international conventions.&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;and Information Providers retain all proprietary rights in the Content and Marks.</span>
          </p>
          <h2 style={{
            marginRight: '0in',
            marginLeft: '0in',
            fontSize: '24px',
            fontFamily: '"Barlow"',
            color: '#2E74B5',
            fontWeight: 'normal'
          }}>Warranty Disclaimer</h2>
          <p style={{
            margin: '0in',
            fontSize: '16px',
            fontFamily: '"Calibri",sans-serif',
            marginBottom: '7.5pt',
            background: 'inherit'
          }}><span style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>THE INFORMATION PROVIDED THROUGH THE SITE IS PROVIDED "AS IS" AND "AS AVAILABLE." YOU AGREE THAT YOUR USE OF THE SITE SHALL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW,&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>, ITS OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF.&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;MAKES NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY, ADEQUACY, TIMELINESS, OR COMPLETENESS OF THIS SITE'S CONTENT OR THE CONTENT OF ANY SITES LINKED TO THIS SITE AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (I) ERRORS, OMISSIONS, MISTAKES, OR INACCURACIES OF CONTENT, (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE, (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (IV) ANY INTERRUPTION, DELAY OR CESSATION OF TRANSMISSION TO OR FROM THE SITE, (IV) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (V) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE.&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;PROVIDES NO GUARANTEE AGAINST THE POSSIBILITY OF DELETION, MIS-DELIVERY OR FAILURE TO STORE COMMUNICATIONS, PERSONALIZED SETTINGS, OR OTHER DATA.&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SITE OR ANY HYPERLINKED WEBSITE OR FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.</span>
          </p>
          <h2 style={{
            marginRight: '0in',
            marginLeft: '0in',
            fontSize: '24px',
            fontFamily: '"Barlow"',
            color: '#2E74B5',
            fontWeight: 'normal'
          }}>Limitation of Liability</h2>
          <p style={{
            margin: '0in',
            fontSize: '16px',
            fontFamily: '"Calibri",sans-serif',
            marginBottom: '7.5pt',
            background: 'inherit'
          }}><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>IN NO EVENT SHALL&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>, ITS OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS, BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES WHATSOEVER RESULTING FROM ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT, (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE, (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITE, (IV) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE, WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, (V) ANY ERRORS OR OMISSIONS IN ANY CONTENT, (VI) YOUR USE, OR INABILITY TO USE, ANY PORTION OF THE SITE OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF YOUR USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT THE COMPANY IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION.&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>WE</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;MAKE NO REPRESENTATIONS THAT THE SITE IS APPROPRIATE OR AVAILABLE FOR USE IN OTHER COUNTRIES. THOSE WHO ACCESS OR USE THE SITE FROM OTHER JURISDICTIONS DO SO AT THEIR OWN VOLITION AND ARE RESPONSIBLE FOR COMPLIANCE WITH LOCAL LAW.</span>
          </p>
          <h2 style={{
            marginRight: '0in',
            marginLeft: '0in',
            fontSize: '24px',
            fontFamily: '"Barlow"',
            color: '#2E74B5',
            fontWeight: 'normal'
          }}>Indemnity</h2>
          <p style={{
            margin: '0in',
            fontSize: '16px',
            fontFamily: '"Calibri",sans-serif',
            marginBottom: '7.5pt',
            background: 'inherit'
          }}><span style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>You agree to indemnify and hold&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;and its affiliates, agents, employees, and licensors harmless from any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees) arising from: (i) your use of and access to the Site; (ii) your violation of any term of these Terms of Service; (iii) your violation of any third party right, including without limitation any copyright, property, or privacy right. This defense and indemnification obligation will survive termination of these Terms of Service or discontinuance of your use of the Site.</span>
          </p>
          <h2 style={{
            marginRight: '0in',
            marginLeft: '0in',
            fontSize: '24px',
            fontFamily: '"Barlow"',
            color: '#2E74B5',
            fontWeight: 'normal'
          }}>Ability to Accept</h2>
          <p style={{
            margin: '0in',
            fontSize: '16px',
            fontFamily: '"Calibri",sans-serif',
            marginBottom: '7.5pt',
            background: 'inherit'
          }}><span style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>By your use of the Site, you affirm that you are either are at least 18 years of age, or an emancipated minor, or possess legal parental or guardian consent, and are fully able and competent to enter into the terms, conditions, obligations, affirmations, representations, and warranties set forth in these Terms of Service, and to abide by and comply with these Terms of Service. In any case, you affirm that you are 13 years old or older, as the Site is not intended for children under that age of 13. If you are under 13 years of age, do not use the Site.</span>
          </p>
          <h2 style={{
            marginRight: '0in',
            marginLeft: '0in',
            fontSize: '24px',
            fontFamily: '"Barlow"',
            color: '#2E74B5',
            fontWeight: 'normal'
          }}>Assignment</h2>
          <p style={{
            margin: '0in',
            fontSize: '16px',
            fontFamily: '"Calibri",sans-serif',
            marginBottom: '7.5pt',
            background: 'inherit'
          }}><span style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>These Terms of Service and any rights and licenses granted hereunder may not be transferred or assigned by you, but may be assigned by&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;without restriction, including without limitation, in the event that&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>, forms a partnership with, is merged with, or is acquired by another company.</span>
          </p>
          <h2 style={{
            marginRight: '0in',
            marginLeft: '0in',
            fontSize: '24px',
            fontFamily: '"Barlow"',
            color: '#2E74B5',
            fontWeight: 'normal'
          }}>Terms and Termination</h2>
          <p style={{
            margin: '0in',
            fontSize: '16px',
            fontFamily: '"Calibri",sans-serif',
            marginBottom: '7.5pt',
            background: 'inherit'
          }}><span style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>Your permission to access the Site under these Terms of Service will immediately terminate if you fail to comply with any term or condition. Upon such violation, you agree to terminate access to the Site. You agree that&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>, in its sole discretion and with or without notice, may terminate your access to any or all of the Site, and remove and discard any Content within the Site.&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;may also in its sole discretion with or without notice discontinue providing the Site. You also agree that&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;will not be liable to you or any third party for any termination of your access to the Site.</span>
          </p>
          <h2 style={{
            marginRight: '0in',
            marginLeft: '0in',
            fontSize: '24px',
            fontFamily: '"Barlow"',
            color: '#2E74B5',
            fontWeight: 'normal'
          }}>Refund Policy</h2>
          <p style={{
            margin: '0in',
            fontSize: '16px',
            fontFamily: '"Calibri",sans-serif',
            marginBottom: '7.5pt',
            background: 'inherit'
          }}><span style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>You may cancel your&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;account at any time, but all payment transactions are considered final and no refunds will be issued unless it is legally required.</span>
          </p>
          <h2 style={{
            marginRight: '0in',
            marginLeft: '0in',
            fontSize: '24px',
            fontFamily: '"Barlow"',
            color: '#2E74B5',
            fontWeight: 'normal'
          }}>Governing Law</h2>
          <p style={{
            margin: '0in',
            fontSize: '16px',
            fontFamily: '"Calibri",sans-serif',
            marginBottom: '7.5pt',
            background: 'inherit'
          }}><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>You agree that&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;and the Site&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: 'black'
            }}>are solely based in&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: 'black'
            }}>Tehran</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: 'black'
            }}>, that the Site is a passive website and that the Site does not give rise to personal jurisdiction over&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: 'black'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: 'black'
            }}>, either specific or general, in jurisdictions other than&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: 'black'
            }}>Iran</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: 'black'
            }}>. You agree that these Terms of Service shall be governed and interpreted pursuant to the laws of&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: 'black'
            }}>Iran</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: 'black'
            }}>, notwithstanding any principles of conflict of law or your actual state or country of residence. You agree to submit to the personal and exclusive jurisdiction of the courts located within&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: 'black'
            }}>Tehran, Iran</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: 'black'
            }}>.</span></p>
          <h2 style={{
            marginRight: '0in',
            marginLeft: '0in',
            fontSize: '24px',
            fontFamily: '"Barlow"',
            color: '#2E74B5',
            fontWeight: 'normal'
          }}>Entire Agreement/Severability Waiver</h2>
          <p style={{
            margin: '0in',
            fontSize: '16px',
            fontFamily: '"Calibri",sans-serif',
            marginBottom: '7.5pt',
            background: 'inherit'
          }}><span style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>These Terms of Service, together with the Privacy Policy and any other legal notices published by&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;on the Site, shall constitute the entire agreement between you and&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;concerning the Site. If any provision of these Terms of Service is deemed invalid by a court of competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of these Terms of Service, which shall remain in full force and effect.</span>
          </p>
          <h2 style={{
            marginRight: '0in',
            marginLeft: '0in',
            fontSize: '24px',
            fontFamily: '"Barlow"',
            color: '#2E74B5',
            fontWeight: 'normal'
          }}>No Waiver</h2>
          <p style={{
            margin: '0in',
            fontSize: '16px',
            fontFamily: '"Calibri",sans-serif',
            marginBottom: '7.5pt',
            background: 'inherit'
          }}><span style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>No waiver of any provision of these Terms of Service shall be deemed a further or continuing waiver of such provision or any other provision, and&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}’s</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;failure to assert any right under these Terms of Service shall not constitute a waiver of such right. YOU AND&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE SITE MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.</span>
          </p>
          <h2 style={{
            marginRight: '0in',
            marginLeft: '0in',
            fontSize: '24px',
            fontFamily: '"Barlow"',
            color: '#2E74B5',
            fontWeight: 'normal'
          }}>Modifications</h2>
          <p style={{
            margin: '0in',
            fontSize: '16px',
            fontFamily: '"Calibri",sans-serif',
            marginBottom: '7.5pt',
            background: 'inherit'
          }}><span style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;reserves the right, in its sole discretion, at any time to modify, discontinue or terminate the site, any portion thereof, or any content thereon without notice, or modify these Terms of Service. If any modified terms and conditions are not acceptable to you, your sole remedy is to cease using the site, and if applicable, cancel your&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;account. By continuing to access or use the site after&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>{window.SITE_TITLE}</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;makes any such revision, you agree to be bound by the revised Terms of Service.</span>
          </p>
          <p style={{
            margin: '0in',
            fontSize: '16px',
            fontFamily: '"Calibri",sans-serif',
            marginBottom: '7.5pt',
            background: 'inherit'
          }}><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>Last Modified:&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>June</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;2</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>7</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>th, 20</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>21</span></p>
          <p style={{
            margin: '0in',
            fontSize: '16px',
            fontFamily: '"Calibri",sans-serif'
          }}>&nbsp;</p>
        </div>
        <div>
          <h1 style={{
            marginRight: '0in',
            marginLeft: '0in',
            fontSize: '32px',
            fontFamily: '"Barlow"',
            color: '#1F4E79'
          }}><span id="privacy-policy" style={{ fontWeight: 'normal' }}>Privacy Policy</span></h1>
          <p style={{
            margin: '0in',
            fontSize: '16px',
            fontFamily: '"Calibri",sans-serif'
          }}><span style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333',
            background: 'inherit'
          }}>{window.SITE_TITLE}&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333',
              background: 'inherit'
            }}>is committed to safeguarding the privacy of&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333',
              background: 'inherit'
            }}>its</span><span style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333',
            background: 'inherit'
          }}>&nbsp;visitors</span><span
            style={{ fontFamily: '"Times New Roman",serif' }}>.&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333',
              background: 'inherit'
            }}>Our Privacy Policy is designed to help you understand how we collect, use and safeguard the information you provide to us and to assist you in making informed decisions when using our web site.</span>
          </p>
          <h2 style={{
            marginRight: '0in',
            marginLeft: '0in',
            fontSize: '24px',
            fontFamily: '"Barlow"',
            color: '#2E74B5',
            fontWeight: 'normal'
          }}>Disclosure of Personal Information</h2>
          <p style={{
            margin: '0in',
            fontSize: '16px',
            fontFamily: '"Calibri",sans-serif',
            marginBottom: '7.5pt',
            background: 'inherit'
          }}><span style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>We are the sole owners of the information collected on this&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>web</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>site. We do not sell, trade, rent, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property, or safety.</span>
          </p>
          <h2 style={{
            marginRight: '0in',
            marginLeft: '0in',
            fontSize: '24px',
            fontFamily: '"Barlow"',
            color: '#2E74B5',
            fontWeight: 'normal'
          }}>Your Rights Over Your Data</h2>
          <p style={{
            margin: '0in',
            fontSize: '16px',
            fontFamily: '"Calibri",sans-serif',
            marginBottom: '7.5pt',
            background: 'inherit'
          }}><span style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>You may opt out of any future contacts from us at any time. You can do the following at any time by</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;</span><span
            style={{ color: 'black' }}><a href={`mailto:${window.SITE_EMAIL}`}><span style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#2E74B5'
          }}>contacting us</span></a></span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>:</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;</span></p>
          <ul style={{ marginBottom: '0in' }} type="disc">
            <li style={{
              margin: '0in',
              fontSize: '16px',
              fontFamily: '"Calibri",sans-serif',
              color: '#333333',
              background: 'inherit'
            }}><span
              style={{
                fontSize: '14px',
                fontFamily: '"Barlow"'
              }}>See what data we have about you, if any.</span>
            </li>
            <li style={{
              margin: '0in',
              fontSize: '16px',
              fontFamily: '"Calibri",sans-serif',
              color: '#333333',
              background: 'inherit'
            }}><span
              style={{
                fontSize: '14px',
                fontFamily: '"Barlow"'
              }}>Change/correct any data we have about you.</span>
            </li>
            <li style={{
              margin: '0in',
              fontSize: '16px',
              fontFamily: '"Calibri",sans-serif',
              color: '#333333',
              background: 'inherit'
            }}><span
              style={{
                fontSize: '14px',
                fontFamily: '"Barlow"'
              }}>Have us delete any data we have about you.</span>
            </li>
            <li style={{
              margin: '0in',
              fontSize: '16px',
              fontFamily: '"Calibri",sans-serif',
              color: '#333333',
              background: 'inherit'
            }}><span style={{
              fontSize: '14px',
              fontFamily: '"Barlow"'
            }}>Express any concern you have about our use of your data.</span>
            </li>
          </ul>
          <h2 style={{
            marginRight: '0in',
            marginLeft: '0in',
            fontSize: '24px',
            fontFamily: '"Barlow"',
            color: '#2E74B5',
            fontWeight: 'normal'
          }}>Data Retention</h2>
          <p style={{
            margin: '0in',
            fontSize: '16px',
            fontFamily: '"Calibri",sans-serif',
            marginBottom: '7.5pt',
            background: 'inherit'
          }}><span style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>For users that register on our website, we store the personal information they provide in their user profile. Users can see, edit, or delete their personal account information at any time. The information may still be retained in historical data backups. Because we operate in a highly regulated industry we feel it is prudent to retain information indefinitely in order to comply with our legal obligations, resolve disputes, and enforce our agreements.</span>
          </p>
          <h2 style={{
            marginRight: '0in',
            marginLeft: '0in',
            fontSize: '24px',
            fontFamily: '"Barlow"',
            color: '#2E74B5',
            fontWeight: 'normal'
          }}>Protecting Personal Information</h2>
          {/* <p style={{
            margin: '0in',
            fontSize: '16px',
            fontFamily: '"Calibri",sans-serif',
            marginBottom: '7.5pt',
            background: 'inherit'
          }}><span style={{fontSize: '14px', fontFamily: '"Barlow"', color: '#333333'}}>We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline. The servers on which we store personally identifiable information are provided by&nbsp;</span><span
            style={{fontSize: '14px', fontFamily: '"Barlow"', color: '#333333'}}>Abr-e Arvan</span><span
            style={{fontSize: '14px', fontFamily: '"Barlow"', color: '#333333'}}>&nbsp;data centers. Your account is protected by your account password and we urge you to take steps to keep your personal information safe by not disclosing your password and by logging out of your account after each use. However, these measures do not guarantee that your information will not be accessed, disclosed, altered or destroyed by breach of such firewalls and secure server software. By using this web site, you acknowledge that you understand and agree to assume these risks.</span>
          </p> */}
          <p style={{
            margin: '0in',
            fontSize: '16px',
            fontFamily: '"Calibri",sans-serif',
            marginBottom: '7.5pt',
            background: 'inherit'
          }}><span style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>
          We take precautions to protect your information. When you submit sensitive information via the website,
          your information is protected both online and offline.&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;Your account is protected by your account password and we urge you to take steps to keep your personal information safe by not disclosing your password and by logging out of your account after each use. However, these measures do not guarantee that your information will not be accessed, disclosed, altered or destroyed by breach of such firewalls and secure server software. By using this web site, you acknowledge that you understand and agree to assume these risks.</span>
          </p>
          <h2 style={{
            marginRight: '0in',
            marginLeft: '0in',
            fontSize: '24px',
            fontFamily: '"Barlow"',
            color: '#2E74B5',
            fontWeight: 'normal'
          }}>Third Party Websites</h2>
          <p style={{
            margin: '0in',
            fontSize: '16px',
            fontFamily: '"Calibri",sans-serif',
            marginBottom: '7.5pt',
            background: 'inherit'
          }}><span style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>Our website includes hyperlinks to third party websites. We have no control over, and are not responsible for, the privacy practices employed by those websites or the information or content they contain.</span>
          </p>
          <h2 style={{
            marginRight: '0in',
            marginLeft: '0in',
            fontSize: '24px',
            fontFamily: '"Barlow"',
            color: '#2E74B5',
            fontWeight: 'normal'
          }}>Terms and Conditions</h2>
          <p style={{
            margin: '0in',
            fontSize: '16px',
            fontFamily: '"Calibri",sans-serif',
            marginBottom: '7.5pt',
            background: 'inherit'
          }}><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>Your use of the&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>web</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>site is subject to our</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;</span><u><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>Terms of Services Agreement</span></u><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>, which establishes the use, disclaimers, and limitations of liability governing the use of our website.</span>
          </p>
          <h2 style={{
            marginRight: '0in',
            marginLeft: '0in',
            fontSize: '24px',
            fontFamily: '"Barlow"',
            color: '#2E74B5',
            fontWeight: 'normal'
          }}>Your Consent</h2>
          <p style={{
            margin: '0in',
            fontSize: '16px',
            fontFamily: '"Calibri",sans-serif',
            marginBottom: '7.5pt',
            background: 'inherit'
          }}><span style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>By using our site, you consent to this privacy policy.</span>
          </p>
          <h2 style={{
            marginRight: '0in',
            marginLeft: '0in',
            fontSize: '24px',
            fontFamily: '"Barlow"',
            color: '#2E74B5',
            fontWeight: 'normal'
          }}>Changes to Privacy Policy</h2>
          <p style={{
            margin: '0in',
            fontSize: '16px',
            fontFamily: '"Calibri",sans-serif',
            marginBottom: '7.5pt',
            background: 'inherit'
          }}><span style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>We reserve the right to change this privacy policy and our Terms of Service at any time. If we change our privacy policy, we will post those changes on this page. You should periodically check this privacy policy page for updates.</span>
          </p>
          <p style={{
            margin: '0in',
            fontSize: '16px',
            fontFamily: '"Calibri",sans-serif',
            marginBottom: '7.5pt',
            background: 'inherit'
          }}><strong><span style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>If you have questions regarding this privacy policy or feel that we are not abiding by this privacy policy, you can</span></strong><strong><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;</span></strong><span
            style={{ color: 'black' }}><a href={`mailto:${window.SITE_EMAIL}`}><strong><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#2E74B5'
            }}>contact us</span></strong></a></span><strong><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;with any questions or concerns.</span></strong>
          </p>
          <p style={{
            margin: '0in',
            fontSize: '16px',
            fontFamily: '"Calibri",sans-serif',
            marginBottom: '7.5pt',
            background: 'inherit'
          }}><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>Last Modified:&nbsp;</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>June</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>&nbsp;2</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>7</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>th, 2</span><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"',
              color: '#333333'
            }}>021</span></p>
        </div>
      </p>
      <div className={classes.checkBox}>
        <hr/>
        <CFormGroup variant="checkbox" className="checkbox mx-5 pt-0">
          <CInputCheckbox
            id="checkbox1"
            name="checkbox1"
            value="option1"
            checked={check}
            onChange={() => setCheck(!check)}
          />
          <CLabel variant="checkbox" className="form-check-label font-lg" htmlFor="checkbox1">Check
            here to indicate you
            have read,
            understood and agree to Terms of Service and Privacy Policy
            {!window.IS_VAVE &&
              '<br/>' + '(متن فوق را مطالعه کردم، فهمیدم و قبول دارم)'}
          </CLabel>
        </CFormGroup>
        <CLink disabled={!check} onClick={() => {
          setModal(false);
          setIsSigned();
        }} className="float-right btn btn-info m-5" color="info"
          // to="/user-account/change-my-password"
        >Continue</CLink>
      </div>
    </Dialog>
  );
}
