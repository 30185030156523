import React from 'react';
// import PageLinks from './pages/main/pages/landing/MainLanding/PageLinks';

const PageLinks = React.lazy(() => import('./pages/main/pages/landing/MainLanding/PageLinks'));
const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'));
const AssetAllocation = React.lazy(() => import('./pages/main/pages/panel/asset-allocation/stategic-asset-allocation'));
const Backtesting = React.lazy(() => import('./pages/main/pages/panel/Backtesting'));
const UserAccount = React.lazy(() => import('./pages/main/pages/panel/UserAccount'));
const SavedData = React.lazy(() => import('./pages/main/pages/panel/SavedData'));
const FactorModels = React.lazy(() => import('./pages/main/pages/panel/factor-models/FactorModels'));
const Settings = React.lazy(() => import('./pages/main/pages/panel/Settings'));
const MonteCarlo = React.lazy(() => import('./pages/main/pages/panel/MonteCarlo'));
const Performance = React.lazy(() => import('./pages/main/pages/panel/Performance'));
const Users = React.lazy(() => import('./views/users/Users'));
const User = React.lazy(() => import('./views/users/User'));
const Bell = React.lazy(() => import('./pages/components/bellcurve'));
const DataReview = React.lazy(() => import('./pages/main/pages/panel/DataReview'));
const Docs = React.lazy(() => import('./pages/main/pages/docs/Docs'));
const PrivacyPolicy = React.lazy(() => import('./pages/main/pages/landing/PrivacyPolicy'));
const Terms = React.lazy(() => import('./pages/main/pages/landing/Terms'));

const routes = [
  {
    path: '/',
    name: 'SimpleTabs',
    component: PageLinks,
  },
  {
    path: '/main',
    name: 'SimpleTabs',
    component: PageLinks,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/asset-allocation',
    name: 'AssetAllocation',
    component: AssetAllocation,
  },
  {
    path: '/factor-models',
    name: 'FactorModels',
    component: FactorModels,
  },

  {
    path: '/backtesting',
    name: 'Backtesting',
    component: Backtesting,
  },
  {
    path: '/user-account',
    name: 'UserAccount',
    component: UserAccount,
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
  },
  // {path: '/performance', name: 'Performance', component: Performance},
  {
    path: '/performance',
    name: 'Performance',
    component: Performance,
  },
  {
    path: '/monte-carlo-simulation',
    name: 'MonteCarlo',
    component: MonteCarlo,
  },
  {
    path: '/data-review',
    name: 'DataReview',
    component: DataReview,
  },
  {
    path: '/bell',
    name: 'Bell',
    component: Bell,
  },
  {
    path: '/users',
    exact: true,
    name: 'Users',
    component: Users,
  },
  {
    path: '/help',
    exact: true,
    name: 'Help',
    component: Docs,
  },
  {
    path: '/my-data',
    exact: true,
    name: 'MyData',
    component: SavedData,
  },
  {
    path: '/user-privacy-policy',
    exact: true,
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
  {
    path: '/user-terms',
    exact: true,
    name: 'Terms',
    component: Terms,
  },
];

export default routes;
