import React, { useState } from 'react';
import {
  CButton,
  CCol,
  CForm,
  CFormGroup,
  CInput,
  CLabel,
  CLink,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTextarea
} from '@coreui/react';
import MaskedInput from 'react-text-mask';
import CIcon from '@coreui/icons-react';
import API from '../../../../api';
import { Link } from 'react-router-dom';
import LandingHeader from '../../../../containers/LandingHeader';
import LandingFooter from '../../../../containers/LandingFooter';

export default function OuterPrivacy() {
  const [showModal, setShowModal] = useState(false);
  const [small, setSmall] = useState(false);
  const [inputs, setInputs] = useState({
    name: '',
    email: '',
    phone_number: '',
    company_name: '',
    position: '',
    enquiry: ''
  });

  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs({
      ...inputs,
      [name]: value
    });
  };

  const submitEnquiry = (e) => {
    e.preventDefault();
    setShowModal(false);
    API.post('userenquiries', inputs)
      .then((r) => {
        if (r.status === 200) {
          setSmall(true);
          setTimeout(() => {
            setSmall(false);
          }, 15000);
        }
      });
  };

  return (
    <>
      <LandingHeader/>
      <div style={{ padding: 35 }}>
        <CModal
          show={showModal}
          onClose={() => {
            setShowModal(false);
          }}
          size="lg">
          <CModalHeader className="border-bottom-0">
            <CModalTitle className="mx-auto mt-2" style={{ fontSize: 35 }}>Contact Us</CModalTitle>
          </CModalHeader>
          <CForm onSubmit={(e) => {
            submitEnquiry(e);
          }}>
            <CModalBody className="px-5">
              <CRow>
                <CCol md="5" className="font-lg my-auto">
                  <CRow>
                    <p className="font-weight-bold mr-4">Email: </p>
                    <p>{window.SITE_EMAIL}</p>
                  </CRow>
                  {!window.IS_VAVE &&
                    <CRow>
                      <p className="font-weight-bold mr-3">Phone: </p>
                      <p>+98 912 283 3276</p>
                    </CRow>
                  }
                </CCol>
                <CCol md="7">
                  <CFormGroup row className="my-0">
                    <CCol md="12">
                      <CFormGroup>
                        <CLabel htmlFor="city">Name <strong
                          className="text-danger">*</strong></CLabel>
                        <CInput id="city" required name="name" onChange={onChangeHandler}/>
                      </CFormGroup>
                    </CCol>
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel htmlFor="vat">Email <strong className="text-danger">*</strong></CLabel>
                    <CInput id="vat" type="email" required name="email" onChange={onChangeHandler}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel htmlFor="street">Phone Number </CLabel>
                    {/*<CInput id="street" placeholder="Enter personal phone number"/>*/}
                    <MaskedInput
                      mask={['+', /[1-9]/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
                      className="form-control"
                      guide={true}
                      id="my-input-id"
                      name="phone_number"
                      requried
                      onChange={onChangeHandler}/>
                    <small className="form-text text-muted">ex. +44 999 999 9999</small>
                  </CFormGroup>
                  <CFormGroup row className="my-0">
                    <CCol xs="7">
                      <CFormGroup>
                        <CLabel htmlFor="city">Company Name </CLabel>
                        <CInput id="city" name="company_name" onChange={onChangeHandler}
                        />
                      </CFormGroup>
                    </CCol>
                    <CCol xs="5">
                      <CFormGroup>
                        <CLabel htmlFor="postal-code">Position</CLabel>
                        <CInput id="postal-code" name="position" onChange={onChangeHandler}
                        />
                      </CFormGroup>
                    </CCol>
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel htmlFor="country">Message </CLabel>
                    <CTextarea onChange={onChangeHandler} name="enquiry"/>
                  </CFormGroup>
                </CCol>
              </CRow>
            </CModalBody>
            <CModalFooter>
              <CButton type="submit" className="text-info">Submit</CButton>
            </CModalFooter>
          </CForm>
        </CModal>
        <CModal
          show={small}
          onClose={() => setSmall(!small)}
          className="mt-5"
          size="md"
        >
          <CModalHeader className="border-bottom-0">
            <CLink className="card-header-action ml-auto" onClick={() => setSmall(false)}>
              <CIcon name="cil-x"/>
            </CLink>
          </CModalHeader>
          <CModalBody className="text-center mb-4">
            <h2 style={{ fontSize: 35 }} className="pb-3">Thank you! </h2>
          </CModalBody>
        </CModal>

        <h1 style={{
          marginRight: '0in',
          marginLeft: '0in',
          fontSize: '32px',
          fontFamily: '"Barlow"',
          color: '#1F4E79'
        }}><span style={{ fontWeight: 'normal' }}>Privacy Policy</span></h1>
        <p style={{
          margin: '0in',
          fontSize: '16px',
          fontFamily: '"Calibri",sans-serif'
        }}><span style={{
          fontSize: '14px',
          fontFamily: '"Barlow"',
          color: '#333333',
          background: 'inherit'
        }}>{window.SITE_TITLE}&nbsp;</span><span
          style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333',
            background: 'inherit'
          }}>is committed to safeguarding the privacy of&nbsp;</span><span
          style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333',
            background: 'inherit'
          }}>its</span><span style={{
          fontSize: '14px',
          fontFamily: '"Barlow"',
          color: '#333333',
          background: 'inherit'
        }}>&nbsp;visitors</span><span
          style={{ fontFamily: '"Times New Roman",serif' }}>.&nbsp;</span><span
          style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333',
            background: 'inherit'
          }}>Our Privacy Policy is designed to help you understand how we collect, use and safeguard the information you provide to us and to assist you in making informed decisions when using our web site.</span>
        </p>
        <h2 style={{
          marginRight: '0in',
          marginLeft: '0in',
          fontSize: '24px',
          fontFamily: '"Barlow"',
          color: '#2E74B5',
          fontWeight: 'normal'
        }}>Disclosure of Personal Information</h2>
        <p style={{
          margin: '0in',
          fontSize: '16px',
          fontFamily: '"Calibri",sans-serif',
          marginBottom: '7.5pt',
          background: 'inherit'
        }}><span style={{
          fontSize: '14px',
          fontFamily: '"Barlow"',
          color: '#333333'
        }}>We are the sole owners of the information collected on this&nbsp;</span><span
          style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>web</span><span
          style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>site. We do not sell, trade, rent, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property, or safety.</span>
        </p>
        <h2 style={{
          marginRight: '0in',
          marginLeft: '0in',
          fontSize: '24px',
          fontFamily: '"Barlow"',
          color: '#2E74B5',
          fontWeight: 'normal'
        }}>Your Rights Over Your Data</h2>
        <p style={{
          margin: '0in',
          fontSize: '16px',
          fontFamily: '"Calibri",sans-serif',
          marginBottom: '7.5pt',
          background: 'inherit'
        }}><span style={{
          fontSize: '14px',
          fontFamily: '"Barlow"',
          color: '#333333'
        }}>You may opt out of any future contacts from us at any time. You can do the following at any time by</span><span
          style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>&nbsp;</span><span
          style={{ color: 'black' }}><a onClick={() => {
          setShowModal(true);
        }}><span style={{
          fontSize: '14px',
          fontFamily: '"Barlow"',
          color: '#2E74B5'
        }}>contacting us</span></a></span><span
          style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>:</span><span
          style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>&nbsp;</span></p>
        <ul style={{ marginBottom: '0in' }} type="disc">
          <li style={{
            margin: '0in',
            fontSize: '16px',
            fontFamily: '"Calibri",sans-serif',
            color: '#333333',
            background: 'inherit'
          }}><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"'
            }}>See what data we have about you, if any.</span>
          </li>
          <li style={{
            margin: '0in',
            fontSize: '16px',
            fontFamily: '"Calibri",sans-serif',
            color: '#333333',
            background: 'inherit'
          }}><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"'
            }}>Change/correct any data we have about you.</span>
          </li>
          <li style={{
            margin: '0in',
            fontSize: '16px',
            fontFamily: '"Calibri",sans-serif',
            color: '#333333',
            background: 'inherit'
          }}><span
            style={{
              fontSize: '14px',
              fontFamily: '"Barlow"'
            }}>Have us delete any data we have about you.</span>
          </li>
          <li style={{
            margin: '0in',
            fontSize: '16px',
            fontFamily: '"Calibri",sans-serif',
            color: '#333333',
            background: 'inherit'
          }}><span style={{
            fontSize: '14px',
            fontFamily: '"Barlow"'
          }}>Express any concern you have about our use of your data.</span>
          </li>
        </ul>
        <h2 style={{
          marginRight: '0in',
          marginLeft: '0in',
          fontSize: '24px',
          fontFamily: '"Barlow"',
          color: '#2E74B5',
          fontWeight: 'normal'
        }}>Data Retention</h2>
        <p style={{
          margin: '0in',
          fontSize: '16px',
          fontFamily: '"Calibri",sans-serif',
          marginBottom: '7.5pt',
          background: 'inherit'
        }}><span style={{
          fontSize: '14px',
          fontFamily: '"Barlow"',
          color: '#333333'
        }}>For users that register on our website, we store the personal information they provide in their user profile. Users can see, edit, or delete their personal account information at any time. The information may still be retained in historical data backups. Because we operate in a highly regulated industry we feel it is prudent to retain information indefinitely in order to comply with our legal obligations, resolve disputes, and enforce our agreements.</span>
        </p>
        <h2 style={{
          marginRight: '0in',
          marginLeft: '0in',
          fontSize: '24px',
          fontFamily: '"Barlow"',
          color: '#2E74B5',
          fontWeight: 'normal'
        }}>Protecting Personal Information</h2>
        {/* <p style={{
          margin: '0in',
          fontSize: '16px',
          fontFamily: '"Calibri",sans-serif',
          marginBottom: '7.5pt',
          background: 'inherit'
        }}><span style={{fontSize: '14px', fontFamily: '"Barlow"', color: '#333333'}}>We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline. The servers on which we store personally identifiable information are provided by&nbsp;</span><span
          style={{fontSize: '14px', fontFamily: '"Barlow"', color: '#333333'}}>Abr-e Arvan</span><span
          style={{fontSize: '14px', fontFamily: '"Barlow"', color: '#333333'}}>&nbsp;data centers. Your account is protected by your account password and we urge you to take steps to keep your personal information safe by not disclosing your password and by logging out of your account after each use. However, these measures do not guarantee that your information will not be accessed, disclosed, altered or destroyed by breach of such firewalls and secure server software. By using this web site, you acknowledge that you understand and agree to assume these risks.</span>
        </p> */}
        <p style={{
          margin: '0in',
          fontSize: '16px',
          fontFamily: '"Calibri",sans-serif',
          marginBottom: '7.5pt',
          background: 'inherit'
        }}>
        <span style={{
          fontSize: '14px',
          fontFamily: '"Barlow"',
          color: '#333333'
        }}>
        We take precautions to protect your information. When you submit sensitive information via the website,
         your information is protected both online and offline.&nbsp;</span>
          <span style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>
         &nbsp;Your account is protected by your account password and we urge you to take steps to keep your personal information safe by not disclosing your password and by logging out of your account after each use.
          However, these measures do not guarantee that your information will not be accessed, disclosed,
           altered or destroyed by breach of such firewalls and secure server software. By using this web site,
            you acknowledge that you understand and agree to assume these risks.</span>
        </p>
        <p style={{
          margin: '0in',
          fontSize: '16px',
          fontFamily: '"Calibri",sans-serif',
          marginBottom: '7.5pt',
          background: 'inherit'
        }}><span style={{
          fontSize: '14px',
          fontFamily: '"Barlow"',
          color: '#333333'
        }}>&nbsp;</span></p>
        <h2 style={{
          marginRight: '0in',
          marginLeft: '0in',
          fontSize: '24px',
          fontFamily: '"Barlow"',
          color: '#2E74B5',
          fontWeight: 'normal'
        }}>Third Party Websites</h2>
        <p style={{
          margin: '0in',
          fontSize: '16px',
          fontFamily: '"Calibri",sans-serif',
          marginBottom: '7.5pt',
          background: 'inherit'
        }}><span style={{
          fontSize: '14px',
          fontFamily: '"Barlow"',
          color: '#333333'
        }}>Our website includes hyperlinks to third party websites. We have no control over, and are not responsible for, the privacy practices employed by those websites or the information or content they contain.</span>
        </p>
        <h2 style={{
          marginRight: '0in',
          marginLeft: '0in',
          fontSize: '24px',
          fontFamily: '"Barlow"',
          color: '#2E74B5',
          fontWeight: 'normal'
        }}>Terms and Conditions</h2>
        <p style={{
          margin: '0in',
          fontSize: '16px',
          fontFamily: '"Calibri",sans-serif',
          marginBottom: '7.5pt',
          background: 'inherit'
        }}><span style={{
          fontSize: '14px',
          fontFamily: '"Barlow"',
          color: '#333333'
        }}>Your use of the&nbsp;</span><span
          style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>web</span><span
          style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>site is subject to our</span><span
          style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>&nbsp;</span><u><Link to="/terms"
                                   style={{
                                     fontSize: '14px',
                                     fontFamily: '"Barlow"',
                                     color: '#2E74B5'
                                   }}>Terms of
          Services Agreement</Link></u><span
          style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>, which establishes the use, disclaimers, and limitations of liability governing the use of our website.</span>
        </p>
        <h2 style={{
          marginRight: '0in',
          marginLeft: '0in',
          fontSize: '24px',
          fontFamily: '"Barlow"',
          color: '#2E74B5',
          fontWeight: 'normal'
        }}>Your Consent</h2>
        <p style={{
          margin: '0in',
          fontSize: '16px',
          fontFamily: '"Calibri",sans-serif',
          marginBottom: '7.5pt',
          background: 'inherit'
        }}><span style={{
          fontSize: '14px',
          fontFamily: '"Barlow"',
          color: '#333333'
        }}>By using our site, you consent to this privacy policy.</span>
        </p>
        <h2 style={{
          marginRight: '0in',
          marginLeft: '0in',
          fontSize: '24px',
          fontFamily: '"Barlow"',
          color: '#2E74B5',
          fontWeight: 'normal'
        }}>Changes to Privacy Policy</h2>
        <p style={{
          margin: '0in',
          fontSize: '16px',
          fontFamily: '"Calibri",sans-serif',
          marginBottom: '7.5pt',
          background: 'inherit'
        }}><span style={{
          fontSize: '14px',
          fontFamily: '"Barlow"',
          color: '#333333'
        }}>We reserve the right to change this privacy policy and our Terms of Service at any time. If we change our privacy policy, we will post those changes on this page. You should periodically check this privacy policy page for updates.</span>
        </p>
        <p style={{
          margin: '0in',
          fontSize: '16px',
          fontFamily: '"Calibri",sans-serif',
          marginBottom: '7.5pt',
          background: 'inherit'
        }}><strong><span style={{
          fontSize: '14px',
          fontFamily: '"Barlow"',
          color: '#333333'
        }}>If you have questions regarding this privacy policy or feel that we are not abiding by this privacy policy, you can</span></strong><strong><span
          style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>&nbsp;</span></strong><span
          style={{ color: 'black' }}><a onClick={() => {
          setShowModal(true);
        }}><strong><span style={{
          fontSize: '14px',
          fontFamily: '"Barlow"',
          color: '#2E74B5'
        }}>contact us</span></strong></a></span><strong><span
          style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>&nbsp;with any questions or concerns.</span></strong>
        </p>
        <p style={{
          margin: '0in',
          fontSize: '16px',
          fontFamily: '"Calibri",sans-serif',
          marginBottom: '7.5pt',
          background: 'inherit'
        }}><span
          style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>Last Modified:&nbsp;</span><span
          style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>June</span><span
          style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>&nbsp;2</span><span
          style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>7</span><span
          style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>th, 2</span><span
          style={{
            fontSize: '14px',
            fontFamily: '"Barlow"',
            color: '#333333'
          }}>021</span></p>
        <p style={{
          margin: '0in',
          fontSize: '16px',
          fontFamily: '"Calibri",sans-serif'
        }}>&nbsp;</p>
      </div>
      <LandingFooter/>
    </>
  );
}
