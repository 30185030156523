import { combineReducers, compose, createStore } from 'redux';
import rootReducer from './pages/main/pages/redux/rootReducer';
import authReducer from './pages/main/pages/redux/authReducer';
import loadingReducer from './pages/main/pages/redux/loadingReducer';
// const initialState = {
//   sidebarShow: 'responsive'
// }

// const changeState = (state = initialState, { type, ...rest }) => {
//   switch (type) {
//     case 'set':
//       return {...state, ...rest }
//     default:
//       return state
//   }
// }
const enhancers = compose(
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);
const reducers = combineReducers({
  rootReducer,
  authReducer,
  loadingReducer,
});

export default createStore(reducers, enhancers);
